<template>
<div class="container mb-3">
    <nav class="navbar navbar-expand-md navbar-light bg-light">
        <div class="container-fluid">
            <router-link to="/" class="navbar-brand">
            <img src="/Logo.png" alt="" width="40" height="40">
            ReinventingWheel
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
                <ul class="navbar-nav me-auto mb-1 mb-lg-0">
                    <li class="nav-item">
                        <router-link class="nav-link active" aria-current="page" to="/">Posts</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link active" to="/about-us">About Us</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link active" to="/contact-us">Contact Us</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>
</template>

<script>
export default {
    setup() {

    },
}
</script>
